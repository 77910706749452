<template>
  <div>
    <main-nav :activeIndex="activeIndex"></main-nav>
    <div class="solution-header-container">
      <div class="solution-header-text">
        <h1 data-caption-delay="0" class="wow fadeInLeft">智能座舱</h1>
        <p data-caption-delay="100" class="wow fadeInRight">适用于智能座舱系统HMI交互及AI视觉的仿真测试方案</p>
        <div class="solution-text-button wow fadeInUp" @click="linkUrl(chatUrl)"><div>立即咨询</div></div>
      </div>
    </div>
    <div class="sw-tab-title" ref="tab" :class="tabFixed ? getTabFixedClass() : ''">
      <div class="container">
        <el-tabs v-model="activeProduct" @tab-click="handleTabClick">
          <el-tab-pane label="智能座舱HIL测试系统" name="1">智能座舱HIL测试系统</el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div :class="tabFixed?'solution-tab-fixed':''">
      <div v-if="activeProduct === '1'">
        <section class="solution-intros wow fadeInLeft">
          <div class="container">
            <div class="normal-title">解决方案介绍</div>
            <div class="all-intro">
              <el-row>
                <el-col :span="24">
                  <div class="intro-text" style="width: 100%;">
                    <p>智能座舱系统HIL测试平台可以实现座舱域控制器在虚拟环境中进行硬件在环的半实物仿真测试，包括主机、仪表、显示屏、摄像头、HUD、TBOX等控制器的系统仿真，
                      用定制化的方案和仪器设备代替人工操作和识别判定，完成信息娱乐功能和性能的自动化测试，为智能座舱软件系统的快速开发、快速迭代提供解决方案。</p>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </section>
        <section class="vehicle-solution-sections cockpitHIL-solution-function wow fadeInRight">
          <div class="container">
            <div class="normal-title">功能特点</div>
            <el-row>
              <el-col>
                <div class="function-intro">
                  <p>功能特点包括功能逻辑验证、画面流转、多屏同步、语音交互、总线监控仿真、响应时间、流畅度等测试，通过HMI界面操控与汽车信号同步的操作，实现智能座舱产品操作+信号→执行→确认的全闭环全覆盖的自动化测试。</p>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :xs="24" :sm="24" :md="24" :lg="14" :xl="14">
                <div class="all-function">
                  <p class="priority-title">优势特点：</p>
                  <ul class="priority-list">
                    <li>系统可配置性高，可根据需求灵活调整</li>
                    <li>支持基于场景模拟及车辆动力学仿真的测试环境</li>
                    <li>自动化程度高，支持多种测试类型</li>
                  </ul>
                </div>
              </el-col>
              <el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10">
                <div class="right-picture-1">
                  <img src="../../../assets/images/solution-cockpitHIL-function-pic.jpeg" alt="">
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col>
                <p class="priority-title" style="margin-top: 0">系统功能：</p>
              </el-col>
              <el-col :xs="24" :sm="24" :md="24" :lg="14" :xl="14">
                <el-table
                  style="font-size: 14px"
                  :data="functionTableData"
                  :header-cell-style="{background:'#296CA8',color:'#FFFFFF'}"
                  border
                  size="small"
                >
                  <el-table-column prop="project" label="项目" width="240"/>
                  <el-table-column prop="description" label="描述" />
                </el-table>
              </el-col>
              <el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10">
                <div class="right-picture-2">
                  <img src="../../../assets/images/solution-cockpitHIL-function-table-pic.jpeg" alt="">
                </div>
              </el-col>
            </el-row>
          </div>
        </section>
        <section class="ethernet-solution-components wow fadeInLeft">
          <div class="container" style="height: 410px;">
            <div class="normal-title">核心组件</div>
            <div class="solution-components">
              <div class="components-content">
                <el-row :gutter="10">
                  <el-col :span="24">
                    <div class="components-table">
                      <el-table
                        style="font-size: 14px;"
                        :data="componentsTableData"
                        :span-method="componentsArraySpanMethod"
                        :header-cell-style="{background:'#296CA8',color:'#FFFFFF'}"
                        border
                        size="small"
                      >
                        <el-table-column prop="type" label="分类" width="100"/>
                        <el-table-column prop="project" label="项目" />
                        <el-table-column prop="description" label="描述/型号" />
                      </el-table>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <bzInfo style="margin-top: 0"/>
    <main-footer></main-footer>
  </div>
</template>

<script>
import mainNav from '@/components/MainNav'
import mainFooter from '@/components/MainFooter'
import bzInfo from '@/components/BzInfo'
export default {
  name: "Cockpit",
  components: {
    mainNav,
    mainFooter,
    bzInfo
  },
  data(){
    return {
      activeIndex: '2-4',
      tabFixed: false,
      activeProduct: '1',
      functionTableData: [
        {
          project: "屏幕自动化测试",
          description: "可模拟人手操作，搭配主动图标识别",
        },
        {
          project: "HUD测试",
          description: "支持基于场景仿真的HUD测试",
        },
        {
          project: "语音自动化测试",
          description: "声音收发及指令识别测试",
        },
        {
          project: "面部识别自动化测试",
          description: "人脸识别自动化测试",
        },
        {
          project: "智能座舱驾驶模拟器测试",
          description: "搭载智能座舱设备的多自由度驾驶模拟器",
        }
      ],
      componentsTableData: [
        {
          type: "硬件",
          project: "实时系统",
          description: "Vector VT平台、NI PXI平台",
        },
        {
          type: "硬件",
          project: "上位机电脑",
          description: "控制主机，运行系统操作及核心软件",
        },
        {
          type: "硬件",
          project: "专用测试台架",
          description: "智能座舱设备测试台架",
        },
        {
          type: "软件",
          project: "虚拟场景仿真软件",
          description: "DYNA4、VTD",
        },
        {
          type: "软件",
          project: "试验管理软件",
          description: "兼容多种成熟管理软件",
        },
        {
          type: "软件",
          project: "车载通信仿真软件",
          description: "CANoe",
        }
      ],
      chatUrl:"https://doc.weixin.qq.com/forms/AOEAXQcgAAkAe4APAaVAD0jHqcmrcFRKf",
      isMobile: false
    }
  },
  mounted() {
    new this.$wow.WOW().init();
    document.title = "智能座舱 - 解决方案 - 东信创智";
    window.addEventListener("scroll", this.handleScroll);
    this.isMobile = document.documentElement.clientWidth < 1200
    window.onresize = () => {
      return (() => {
        this.isMobile = document.documentElement.clientWidth < 1200;
      })();
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    linkUrl(url){
      window.open(url,'_blank') // 在新窗口打开外链接
      // window.location.href =this.indexro;  //在本页面打开外部链接
    },
    handleScroll() {
      if (document.documentElement.clientWidth > 1100) {
        let top = this.$refs.tab.offsetTop - document.documentElement.scrollTop - 70
        if (top < 0 && top > -630) {
          this.tabFixed = !this.tabFixed;
        } else if (document.documentElement.scrollTop > 630) {
          this.tabFixed = true;
        }
      } else {
        let top = this.$refs.tab.offsetTop - document.documentElement.scrollTop - 50
        if (top < 0 && top > -250) {
          this.tabFixed = !this.tabFixed;
        } else if (document.documentElement.scrollTop > 250) {
          this.tabFixed = true;
        }
      }
    },
    getTabFixedClass() {
      if (document.documentElement.clientWidth < 1100) {
        return 'sw-tab-title-mobile-fixed'
      } else {
        return 'sw-tab-title-fixed'
      }
    },
    handleTabClick() {
      if (document.documentElement.clientWidth > 1100 && document.documentElement.scrollTop > 630) {
        document.documentElement.scrollTop = 630
      } else if (document.documentElement.clientWidth < 1100 && document.documentElement.scrollTop > 250) {
        document.documentElement.scrollTop = 250
      }
    },
    // eslint-disable-next-line no-unused-vars
    functionArraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0 && ( rowIndex === 0 || rowIndex === 1 || rowIndex === 2)) {
        if (rowIndex % 3 === 0) {
          return {
            rowspan: 3,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },
    // eslint-disable-next-line no-unused-vars
    componentsArraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0 && (rowIndex === 0 || rowIndex === 1 || rowIndex === 2)) {
        if (rowIndex % 3 === 0) {
          return {
            rowspan: 3,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
      if (columnIndex === 0 && (rowIndex === 3 || rowIndex === 4 || rowIndex === 5)) {
        if (rowIndex % 3 === 0) {
          return {
            rowspan: 3,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },
  }
}
</script>

<style scoped>

</style>
